@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/metropolis-medium.woff2') format('woff2'),
    url('/fonts/metropolis-medium.woff') format('woff');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/metropolis-semi-bold.woff2') format('woff2'),
    url('/fonts/metropolis-semi-bold.woff') format('woff');
}

:root {
  --background: #f6ebeb;
  --primary: #d63c5b;
  --text: rgb(100, 100, 100);
  --heading: rgb(24, 24, 24);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Metropolis', sans-serif;
  background-color: var(--background);
  text-align: left;
  font-weight: 500;
  line-height: 1.4;
  color: var(--text);
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1.2;
  color: var(--heading);
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.header {
  padding: 24px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 4;
  transition: background-color 400ms ease-in-out 0s;
  background-color: transparent;
}

.header.scrolled {
  background-color: var(--background);
}

.header .navigation {
  flex-grow: 1;
  text-align: right;
}

@media (max-width: 720px) {
  .header {
    flex-direction: column;
    position: static;
  }
  .header .navigation {
    margin-top: 24px;
    text-align: center;
  }
}

.header .navigation .button {
  border-radius: 24px;
  background-color: var(--primary);
  padding: 4px 12px;
  margin-left: 12px;
  font-weight: 600;
  font-size: 18px;
  display: inline-block;
  color: white;
  transition: all 2ms ease-in-out;
}

.header .navigation .button:hover {
  background-color: white;
  color: inherit;
}

.header .navigation svg {
  display: inline-block;
  margin-right: 6px;
  vertical-align: text-top;
}

.logo a {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--heading);
}

.logo img {
  width: 48px;
}

.logo span {
  padding-left: 12px;
  font-weight: 700;
  font-size: 24px;
}

.main {
  min-height: 500px;
  max-width: 1450px;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 120px;
}

@media (max-width: 720px) {
  .main {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
  }
}

.intro {
  max-width: 1450px;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-left: 64px;
  padding-right: 64px;
  margin: auto;
  justify-content: flex-start;
}

.intro a {
  background-color: var(--primary);
  padding: 8px 8px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 16px;
  color: white;
  transition: all 2ms ease-in-out;
}

.intro a:hover {
  background-color: white;
  color: inherit;
}

.introText {
  width: 90%;
  font-size: 24px;
}

.introImg {
  width: 66%;
  position: relative;
}

.introImg .phone {
  top: 80px;
  position: relative;
  margin-bottom: 0;
  z-index: 3;
  animation: 3s ease-in-out 0s infinite alternate none running phone;
}

.introImg .shadow {
  bottom: -120px;
  z-index: 2;
  position: absolute;
  display: block;
  opacity: 0.5;
}

@media (max-width: 720px) {
  .intro {
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    flex-direction: column;
    height: auto;
  }
  .introImg {
    width: auto;
  }
  .introImg img.phone {
    top: 20px;
  }
  .introImg img.shadow {
    bottom: -20px;
  }
}

.screens {
  display: flex;
  flex-direction: column;
  padding: 64px;
  margin-top: 64px;
  justify-content: space-between;
}

.screens .screen {
  padding: 24px 48px;
  display: flex;
  flex-direction: row;
}

.screens .screen.reverse {
  flex-direction: row-reverse;
}

.screens .screen .screenImage {
  width: 428px;
  padding: 64px;
}
.screens .screen .screenText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66%;
  padding: 64px;
}

@media (max-width: 720px) {
  .screens {
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    flex-direction: column;
  }

  .screens .screen {
    width: auto;
    display: block;
    padding: 0;
    flex-direction: row;
  }
  .screens .screen .screenImage {
    margin: auto;
    width: auto;
    padding: 12px;
  }
  .screens .screen .screenText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    padding: 0;
  }
}

.store_badge {
  height: 64px;
  padding: 6px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexcolumn {
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 64px;
  max-width: 1450px;
}

.footer p {
  font-weight: 700;
}

.footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 12px;
  transition: all 200ms ease-in-out 0s;
}

.footer ul li a {
  color: var(--text);
}

.footer ul li a:hover,
.footer ul li a.active {
}

@media (max-width: 720px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }
}

@keyframes phone {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-5%) rotate(-2deg);
  }
}

@keyframes shadow {
  0% {
    opacity: 1;
    transform: translate(0) scale(1);
  }
  100% {
    opacity: 0.75;
    transform: translate(2%, 6%) scale(0.85);
  }
}
